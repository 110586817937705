import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  AuthenticationProvider,
  oidcLog,
  OidcSecure,
  useReactOidc
} from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { config, oidcConfig } from './utils/config';

import AdminLayout from './layouts/AdminLayout';
import AdminDashboard from './screens/Admin/Dashboard';
import AttributeStore from './screens/Admin/AttributeStore';
import Mfdx from './screens/Admin/Mfdx';
import Viewpoint from './screens/Admin/Viewpoint';
import InteractSoap from './screens/Admin/Interact/Soap';
import WebEditorSettings from './screens/Admin/Interact/WebEditor';

import Invoices from './screens/Admin/Billing/Invoices';
import BillingOverview from './screens/Admin/Billing/Overview';
import PaymentMethods from './screens/Admin/Billing/PaymentMethods';
import Payments from './screens/Admin/Billing/Payments';

import CompanyCreate from './screens/Admin/Company/Create';
import Dashboard from './screens/Dashboard';

import Error from './screens/Error';
import Oidc from './screens/Oidc';
import TeamUser from './screens/Admin/Team/User';
import ManageTeam from './screens/Admin/Team/Manage';
import TeamInvite from './screens/Admin/Team/Invite';
import TeamRoles from './screens/Admin/Team/Roles';
import TeamGroups from './screens/Admin/Team/Groups';
import SceneLayout from './layouts/SceneLayout';
import SettingsNotifications from './screens/Admin/Settings/Notifications';
import Plans from './screens/Plans';
import PurchasePlan from './screens/Plans/Purchase';
import DefaultLayout from './layouts/DefaultLayout';
import PurchaseSuccess from './screens/Plans/Purchase/purchaseSuccess';
import SettingsAccount from './screens/Admin/Settings/Account';
import { getMyUser } from './api/myUser';
import { setAuthHeader } from './api/api';
import { AuthenticatedRoutesContext } from './contexts';
import { useIsAdmin } from './hooks/useIsAdmin';
import UserProfile from './screens/User/UserProfile';
import { featureFlags } from './utils/features';
import {
  NavPromptContainer,
  promptNav as promptNavAlert
} from './components/Modals/navPromptModal';
import { Toaster } from './toaster';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { getStripePublishableKey } from './api/stripeConfig';
import Role from './screens/Admin/Team/Role';
import {
  adminBillingFeatureCode,
  adminConfigurationFeatureCode,
  adminInvitesFeatureCode,
  adminUserFeatureCode
} from './utils/constants';
import { useHasFeature } from './hooks/useHasFeature';
import Group from './screens/Admin/Team/Group';
import SigningIntegration from './screens/Admin/Integration/DigitalSigning';
import ArchiveIntegration from './screens/Admin/Integration/ArchiveIntegration/index';
import SettingsBranding from './screens/Admin/Settings/Branding';
import { convertHexToRGB } from './utils/colors';
import RetentionPolicies from './screens/Admin/Settings/RetentionPolicies';
import ApiClients from './screens/Admin/Integration/ApiClients';
import NotActiveCustomer from './screens/Admin/NotActiveCustomer';
import useIsNotActiveCustomer from './hooks/useIsNotActiveCustomer';
import AuditForward from './screens/Admin/Integration/AuditForward';
import { useClassNames } from './hooks/useClassNames';
import ReleasePackageDetails from './screens/Admin/Versions/ReleasePackageDetails';
import VersionCustomersSettings from './screens/Admin/Versions/VersionCustomersSettings';
import VersionReleasePackages from './screens/Admin/Versions/VersionReleasePackages';
import InteractGeneral from './screens/Admin/Interact/General';

function AuthenticatedRoutes() {
  library.add(fab, faCcVisa);

  const { oidcUser, logout, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  const [myUser, setMyUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stripePublishableKey, setStripePublishableKey] = useState('');

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  const loadMyUser = async () => {
    const user = await getMyUser();
    setMyUser(user);
    setLoading(false);
  };

  const loadStripePk = async () => {
    const spk = await getStripePublishableKey();
    setStripePublishableKey(spk);
  };

  useEffect(() => {
    loadMyUser();
    loadStripePk();
  }, []);
  useEffect(() => {
    setAuthHeader(oidcUser.access_token);
  }, [myUser]);

  const isSppUserGuard = () => {
    return myUser.isSppUser;
  };

  return (
    !loading && (
      <AuthenticatedRoutesContext.Provider
        value={{
          centerPointUser: myUser,
          onLogout: () => logout(),
          triggerContextChange: () => loadMyUser(),
          stripePk: stripePublishableKey
        }}
      >
        <Router
          getUserConfirmation={(message, callback) => {
            promptNavAlert(message, callback);
          }}
        >
          <Switch>
            <Route exact={true} path="/error" component={Error} />

            <SceneLayoutRoute
              exact={true}
              path="/company/create"
              nav="account"
              component={CompanyCreate}
              subMenuNav
            />

            {/* Admin routes */}
            <AdminLayoutRoute
              exact={true}
              path="/admin/"
              nav="admin"
              component={AdminRoot}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/dashboard"
              nav="dashboard"
              component={AdminDashboard}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              path="/admin/attributestore/:environment"
              nav="attributestore/"
              component={AttributeStore}
              guard={isSppUserGuard}
              featureEnabled={featureFlags.attributeStore}
            />
            <AdminLayoutRoute
              path="/admin/mfdx/:environment"
              nav="mfdx"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={Mfdx}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/manage"
              nav="team/manage"
              component={ManageTeam}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/manage/:userId"
              nav="team/manage"
              requiredFeatureCode={adminUserFeatureCode}
              component={TeamUser}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/invite"
              nav="team/invite"
              requiredFeatureCode={adminInvitesFeatureCode}
              component={TeamInvite}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/roles"
              nav="team/roles"
              requiredFeatureCode={adminUserFeatureCode}
              component={TeamRoles}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/role/:roleId"
              nav="team/roles"
              requiredFeatureCode={adminUserFeatureCode}
              component={Role}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/role"
              nav="team/roles"
              component={Role}
              requiredFeatureCode={adminUserFeatureCode}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/groups"
              nav="team/groups"
              requiredFeatureCode={adminUserFeatureCode}
              component={TeamGroups}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/group"
              nav="team/groups"
              requiredFeatureCode={adminUserFeatureCode}
              component={Group}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/team/group/:groupId"
              nav="team/groups"
              requiredFeatureCode={adminUserFeatureCode}
              component={Group}
              featureEnabled={featureFlags.manageTeam}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/settings/notifications"
              nav="settings/notifications"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={SettingsNotifications}
              featureEnabled={featureFlags.settings}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/settings/account"
              nav="settings/account"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={SettingsAccount}
              featureEnabled={featureFlags.settings}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/settings/branding"
              nav="settings/branding"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={SettingsBranding}
              featureEnabled={featureFlags.settings}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/settings/retentionPolicies"
              nav="settings/retentionPolicies"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={RetentionPolicies}
              featureEnabled={featureFlags.settings}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/viewpoint"
              nav="viewpoint"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={Viewpoint}
              featureEnabled={featureFlags.viewPoint}
            />            
            <AdminLayoutRoute
              exact={true}
              path="/admin/interact/general"
              nav="interact/general"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={InteractGeneral}
              featureEnabled={featureFlags.interact}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/interact/soap"
              nav="interact/soap"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={InteractSoap}
              featureEnabled={featureFlags.interact}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/interact/webeditorsettings"
              nav="interact/webeditorsettings"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={WebEditorSettings}
              featureEnabled={featureFlags.interact}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/billing/overview"
              nav="billing/overview"
              requiredFeatureCode={adminBillingFeatureCode}
              component={BillingOverview}
              featureEnabled={featureFlags.billing}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/billing/invoices"
              nav="billing/invoices"
              requiredFeatureCode={adminBillingFeatureCode}
              component={Invoices}
              featureEnabled={featureFlags.billing}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/billing/paymentmethods"
              nav="billing/paymentmethods"
              requiredFeatureCode={adminBillingFeatureCode}
              component={PaymentMethods}
              featureEnabled={featureFlags.billing}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/billing/payments"
              nav="billing/payments"
              requiredFeatureCode={adminBillingFeatureCode}
              component={Payments}
              featureEnabled={featureFlags.billing}
              hideNavigation={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/integration/digitalsigning"
              nav="integration/digitalsigning"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={SigningIntegration}
              featureEnabled={featureFlags.integration}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/integration/digitalarchive"
              nav="integration/digitalarchive"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={ArchiveIntegration}
              featureEnabled={featureFlags.integrationArcive}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/integration/apiclients"
              nav="integration/apiclients"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={ApiClients}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/integration/auditlog-forward"
              nav="integration/auditlog-forward"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={AuditForward}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/version/releasePackages"
              nav="version/releasePackages"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={VersionReleasePackages}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/version/customerNotifications"
              nav="version/customerNotifications"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={VersionCustomersSettings}
              featureEnabled={true}
            />
            <AdminLayoutRoute
              exact={true}
              path="/admin/releasePackageDetails/:id"
              nav="releasePackageDetails"
              requiredFeatureCode={adminConfigurationFeatureCode}
              component={ReleasePackageDetails}
              featureEnabled={true}
            />

            {/* Root here */}
            {/* <Route exact={true} path="/dashboard" component={withOidcSecure(Dashboard)} /> */}
            <DefaultLayoutRoute
              exact={true}
              path="/dashboard"
              nav="dashboard"
              component={Dashboard}
            />
            <DefaultLayoutRoute exact={true} path="/plans" nav="plans" component={Plans} />
            <DefaultLayoutRoute
              path="/plans/purchase/success/:planId"
              nav="plans/purchase/success"
              component={PurchaseSuccess}
            />
            <DefaultLayoutRoute
              path="/plans/purchase/:planId"
              nav="plans/purchase"
              component={PurchasePlan}
            />
            <DefaultLayoutRoute path="/userprofile" nav="userprofile" component={UserProfile} />
            <DefaultLayoutRoute path="/" nav="dashboard" component={Dashboard} />
          </Switch>
        </Router>
      </AuthenticatedRoutesContext.Provider>
    )
  );
}

function App() {
  useEffect(() => {
    document.title = config.appTitle;
    const faviconEl = document.getElementById('favicon');
    faviconEl.href = config.favicon;

    const root = document.documentElement;
    root.style.setProperty('--color-text-primary', convertHexToRGB('#111827'));
    root.style.setProperty('--color-text-secondary', convertHexToRGB('#52525b'));
  }, []);

  return (
    <div className="app">
      <AuthenticationProvider
        configuration={oidcConfig}
        loggerLevel={oidcLog.ERROR}
        isEnabled={true}
        callbackComponentOverride={Oidc}
        notAuthenticated={() => {
          return <h1>Not Authenticated</h1>;
        }}
        notAuthorized={() => {
          return <h1>Not Authorized</h1>;
        }}
        authenticating={() => {
          return <h1>Authentication in process</h1>;
        }}
      >
        <OidcSecure>
          <AuthenticatedRoutes />
        </OidcSecure>
      </AuthenticationProvider>
      <ToastContainer hideProgressBar={true} />
      <Toaster />
      <NavPromptContainer />
    </div>
  );
}

function DefaultLayoutRoute({ component: Component, nav, subMenuNav, ...rest }) {
  const isNotActiveCustomer = useIsNotActiveCustomer();
  const { classNames } = useClassNames();

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout
          nav={nav}
          subNav={subMenuNav}
          customClassName={classNames(isNotActiveCustomer && 'relative')}
          {...props}
        >
          <>
            <Component {...props} />
            {isNotActiveCustomer && <NotActiveCustomer {...props} />}
          </>
        </DefaultLayout>
      )}
    />
  );
}

function SceneLayoutRoute({ component: Component, nav, subMenuNav, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <SceneLayout nav={nav} subNav={subMenuNav} {...props}>
          <Component {...props} />
        </SceneLayout>
      )}
    />
  );
}

function AdminRoot() {
  return <Redirect to="/admin/dashboard"></Redirect>;
}

function AdminLayoutRoute({
  component: Component,
  nav,
  featureEnabled,
  subMenuNav,
  guard,
  hideNavigation,
  requiredFeatureCode,
  ...rest
}) {
  const { hasFeature } = useHasFeature();
  const isNotActiveCustomer = useIsNotActiveCustomer();
  const isAdmin = useIsAdmin();

  const guardMet = guard ? guard() : true;

  return (
    isAdmin !== null && (
      <Route
        {...rest}
        render={(props) => (
          <ProtectedRoute
            featureEnabled={featureEnabled}
            hasAccess={isAdmin && hasFeature(requiredFeatureCode) && guardMet}
          >
            <AdminLayout
              nav={nav}
              subMenuNav={subMenuNav}
              hideNavigation={hideNavigation}
              {...props}
            >
              <>
                <Component {...props} />
                {isNotActiveCustomer && <NotActiveCustomer {...props} />}
              </>
            </AdminLayout>
          </ProtectedRoute>
        )}
      />
    )
  );
}

const ProtectedRoute = ({ featureEnabled, hasAccess, children }) => {
  return featureEnabled && hasAccess ? <>{children}</> : <Redirect to={'/'} />;
};

export default App;
