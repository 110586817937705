import { useState, useEffect } from "react";
import * as yup from "yup";
import { useToastAction } from "../../../../../hooks/useToastAction";

import { SidebarSelect } from "../../../../../components/SidebarSelect";
import { SidebarNumber } from "../../../../../components/SidebarNumber";
import { SidebarCheckbox } from "../../../../../components/SidebarCheckbox";
import { SidebarToggle } from "../../../../../components/SidebarToggle";
import { SidebarPassword } from "../../../../../components/SidebarPassword";
import { getRetentionPoliciesApi } from "../../../../../api/retentionPolicies";
import { getLanguages } from "../../../../../api/language";
import SidebasTextboxPrefixed from "../../../../../components/SidebarTextboxPrefixed";
import { useHasFeature } from "../../../../../hooks/useHasFeature";
import { textLibraryProductCode, textLibraryUsageFeatureCode } from "../../../../../utils/constants";

export default function Properties({ webEditorSettings, setWebEditorSettings, allWebEditorSettings, allCustomerEnvironments, setOpen, onSubmit }) {
    const [errors, setErrors] = useState({});
    const [environmentOptions, setEnvironmentOptions] = useState([]);
    const [retentionPolicies, setRetentionPolicies] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [textLibraryDisabled, setTextLibraryDisabled] = useState(false);
    const { hasUsageFeature } = useHasFeature();

    const loadAction = useToastAction();

    const allColorDepths = [
        { name: "Black and White (1 bit)", value: 1 },
        { name: "Color, RGB (24 bit)", value: 24 },
    ];

    const viewpointArchiveOptions = [
        { name: 'Viewpoint', value: 0 },
        { name: 'On-prem', value: 1 },
    ]

    const environmentScopes = [
        { name: 'Select environment scope', value: '' },
        { name: 'Development', value: 'webeditor.dev' },
        { name: 'Test', value: 'webeditor.test' },
        { name: 'Stage', value: 'webeditor.stage' },
        { name: 'Demo', value: 'webeditor.demo' },
        { name: 'Production', value: 'webeditor.prod' },
    ]

    const loadBaseData = async () => {
        loadAction.execute(async () => {
            let [retentionPoliciesResult, languagesData] = await Promise.all([
                getRetentionPoliciesApi(),
                getLanguages(),
            ]);

            setLanguages(languagesData.map(x => { return { name: x.name, value: x.languageCode } }));

            retentionPoliciesResult = retentionPoliciesResult.map(policy => { return { name: policy.name, value: policy.id } });
            retentionPoliciesResult.unshift({ name: 'Select retention policy', value: '' });
            setRetentionPolicies(retentionPoliciesResult);

            const environments = allCustomerEnvironments.filter(({ value }) =>
                allWebEditorSettings.find(settings => settings.customerEnvironment === value) === undefined ||
                webEditorSettings.customerEnvironment === value);
            setEnvironmentOptions(environments);
            if (!webEditorSettings.id) {
                webEditorSettings.customerEnvironment = environments[0].value;
            }
            if (!webEditorSettings.retentionPolicyId && retentionPoliciesResult?.length > 0) {
                webEditorSettings.retentionPolicyId = retentionPoliciesResult[0].id;
            }

            setTextLibraryDisabled(!hasUsageFeature(textLibraryProductCode, textLibraryUsageFeatureCode));
        }, "Failed to load")
    }

    useEffect(() => {
        loadBaseData();
    }, [])

    let schema = yup.object().shape({
        customerEnvironment: yup.string().required("Customer Environment is required"),
        centralDistributionEnabled: yup.boolean(),
        centralDistributionKivraEnabled: yup.boolean(),
        centralDistributionDigipostEnabled: yup.boolean(),
        centralDistributionCentralprintEnabled: yup.boolean(),
        localPrintEnabled: yup.boolean(),
        emailEnabled: yup.boolean(),
        archiveOnlyEnabled: yup.boolean(),
        pdfAttachmentEnabled: yup.boolean(),
        pdfAttachmentDPI: yup.number().when("pdfAttachmentEnabled", {
            is: (value) => value === true,
            then: yup.number().min(72, "DPI must be greater than or equal to 72.").max(300, "DPI must be less than or equal to 300").integer("DPI must be an integer"),
            otherwise: yup.number().notRequired()
        }),
        mfdxClientSecret: yup.string().required("Client secret is required"),
        requireEditorAuth: yup.boolean(),
        requiredUserDomain: yup.string().nullable().when('requireEditorAuth',
            {
                is: true,
                then: yup.string().required("Domain must be provided when editor authentication is enabled")
            }
        )
    });

    // extended validation
    schema = schema.test(
        'distributionTest',
        null,
        (obj) => {
            let distributionCount = 0;
            if (obj.centralDistributionEnabled) {
                distributionCount += 1;
            }
            if (obj.localPrintEnabled) {
                distributionCount += 1;
            }
            if (obj.emailEnabled) {
                distributionCount += 1;
            }
            if (obj.archiveOnlyEnabled) {
                distributionCount += 1;
            }
            if (!webEditorSettings.endFormEnabled || distributionCount >= 1) {
                return true;
            }

            return new yup.ValidationError(
                'Select at least one option for Distribution',
                null,
                'emailEnabled'
            );
        }
    );

    schema = schema.test(
        'centralDistributionTest',
        null,
        (obj) => {
            if (!obj.centralDistributionEnabled || (obj.centralDistributionKivraEnabled || obj.centralDistributionDigipostEnabled || obj.centralDistributionCentralprintEnabled)) {
                return true;
            }

            return new yup.ValidationError(
                'Select at least one option for Central distribution',
                null,
                'centralDistributionEnabled'
            );
        }
    );



    const validate = async () => {
        var isValid = false

        await schema
            .validate(webEditorSettings, { abortEarly: false })
            .then(function () {
                isValid = true;
                setErrors({});
            })
            .catch(function (err) {
                var newErrors = {}

                err?.inner?.forEach(element => {
                    newErrors[element.path] = `${element.errors[0]}`
                });

                setErrors(newErrors);
            });

        return isValid;
    }

    const handleChange = ({ name, type, value, checked }) => {
        if (type === "checkbox") {
            setWebEditorSettings(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setWebEditorSettings(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleArchiveChange = (value) => {
        setWebEditorSettings(prevState => ({
            ...prevState,
            archiveMethod: value
        }));
    };

    const handleToggleChange = (name, value) => {
        setWebEditorSettings(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        var isValid = await validate();
        if (isValid) {
            webEditorSettings.customerEnvironment = Number(webEditorSettings.customerEnvironment);
            webEditorSettings.pdfAttachmentColorDepth = Number(webEditorSettings.pdfAttachmentColorDepth);
            if (webEditorSettings.pdfAttachmentEnabled === false) {
                webEditorSettings.pdfAttachmentColorDepth = 1;
                webEditorSettings.pdfAttachmentDPI = 150;
                webEditorSettings.pdfAttachmentDuplex = true;
            }
            onSubmit();
        }
    }

    return (
        <>
            <div className="flex w-full overflow-y-auto h-full">
                <div className="w-full">
                    <div className="py-5">
                        <SidebarSelect
                            name="customerEnvironment"
                            value={webEditorSettings.customerEnvironment}
                            label={"Customer Environment"}
                            onChange={e => handleChange(e.target)}
                            error={errors.customerEnvironment}
                            options={environmentOptions}
                        />
                    </div>
                    <div className="">

                        <div className="">
                            <div className="py-3">
                                <h3 className="text-lg leading-5 font-medium text-gray-900 px-4 sm:px-6">Settings</h3>
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Retention Policies</h3>
                                </div>

                                <SidebarSelect
                                    className={"px-0"}
                                    name="retentionPolicyId"
                                    value={webEditorSettings.retentionPolicyId}
                                    label={"Retention Policies"}
                                    onChange={e => handleChange(e.target)}
                                    options={retentionPolicies}
                                />
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Security</h3>
                                </div>

                                <SidebarSelect
                                    className={"px-0"}
                                    name="environmentScope"
                                    value={webEditorSettings.environmentScope}
                                    label={"Environment scope (optional)"}
                                    onChange={e => handleChange(e.target)}
                                    options={environmentScopes}
                                />
                                <p className="px-4 sm:px-6 text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">Scope: {webEditorSettings.environmentScope}</p>
                                <SidebarToggle
                                    label={"Require Editor Authentication"}
                                    value={webEditorSettings.requireEditorAuth}
                                    onChange={value => handleToggleChange('requireEditorAuth', value)}

                                />
                                {
                                    webEditorSettings.requireEditorAuth && (
                                        <SidebasTextboxPrefixed prefix={'@'} label={'Required User Domain'}
                                            onChange={e => handleChange(e.target)}
                                            name={'requiredUserDomain'}
                                            value={webEditorSettings.requiredUserDomain}
                                            error={errors.requiredUserDomain}
                                        />
                                    )
                                }
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Languages</h3>
                                </div>

                                <SidebarSelect
                                    className={"px-0"}
                                    name="languageCode"
                                    value={webEditorSettings.languageCode}
                                    label={"Preferred Language"}
                                    onChange={e => handleChange(e.target)}
                                    options={languages}
                                />
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Visibility</h3>
                                </div>

                                <SidebarToggle
                                    label={"Show Text Library"}
                                    value={webEditorSettings.textLibraryEnabled}
                                    onChange={value => handleToggleChange('textLibraryEnabled', value)}
                                    disabled={textLibraryDisabled}
                                />
                                <SidebarToggle
                                    label={"Show Preview Html"}
                                    value={webEditorSettings.previewHtmlEnabled}
                                    onChange={value => handleToggleChange('previewHtmlEnabled', value)}
                                />
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">PDF Attachment</h3>
                                </div>
                                <SidebarToggle
                                    label={"Enable PDF Attachment"}
                                    value={webEditorSettings.pdfAttachmentEnabled}
                                    onChange={value => handleToggleChange('pdfAttachmentEnabled', value)}
                                    error={errors.pdfAttachmentEnabled}
                                />

                                {webEditorSettings.pdfAttachmentEnabled && (
                                    <>
                                        <SidebarSelect
                                            className={"px-0"}
                                            name="pdfAttachmentColorDepth"
                                            value={webEditorSettings.pdfAttachmentColorDepth}
                                            label={"Color Depth"}
                                            onChange={e => handleChange(e.target)}
                                            options={allColorDepths}
                                        />
                                        <SidebarNumber
                                            name="pdfAttachmentDPI"
                                            value={webEditorSettings.pdfAttachmentDPI}
                                            label={"DPI"}
                                            min={72}
                                            max={300}
                                            step={1}
                                            onChange={e => handleChange(e.target)}
                                            error={errors.pdfAttachmentDPI}
                                        />
                                        <div className="my-2 w-full">
                                            <SidebarToggle
                                                label={"Duplex"}
                                                value={webEditorSettings.pdfAttachmentDuplex}
                                                onChange={value => handleToggleChange('pdfAttachmentDuplex', value)}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Distribution</h3>
                                </div>
                                <div className="pb-2 w-full">
                                    <SidebarToggle
                                        label={"Enable Distribution"}
                                        value={webEditorSettings.endFormEnabled}
                                        onChange={value => handleToggleChange('endFormEnabled', value)}
                                    />
                                </div>
                                {webEditorSettings.endFormEnabled && (
                                    <>
                                        <div className="pb-3 px-4 sm:px-6">
                                            <h5 className="text-sm leading-6 font-medium text-gray-900">Distribution - output</h5>
                                        </div>
                                        <div class="px-4 sm:px-6 w-full space-y-3">
                                            <div>
                                                <SidebarCheckbox
                                                    name="centralDistributionEnabled"
                                                    value={webEditorSettings.centralDistributionEnabled}
                                                    label={"Central distribution"}
                                                    onChange={e => handleChange(e.target)}
                                                    error={errors.centralDistributionEnabled}
                                                />
                                                {webEditorSettings.centralDistributionEnabled &&
                                                    <div className="space-y-1 pt-1 px-4">
                                                        <SidebarCheckbox
                                                            name="centralDistributionKivraEnabled"
                                                            value={webEditorSettings.centralDistributionKivraEnabled}
                                                            label={"Kivra"}
                                                            onChange={e => handleChange(e.target)}
                                                            error={errors.centralDistributionKivraEnabled}
                                                        />
                                                        <SidebarCheckbox
                                                            name="centralDistributionDigipostEnabled"
                                                            value={webEditorSettings.centralDistributionDigipostEnabled}
                                                            label={"Digipost"}
                                                            onChange={e => handleChange(e.target)}
                                                            error={errors.centralDistributionDigipostEnabled}
                                                        />
                                                        <SidebarCheckbox
                                                            name="centralDistributionCentralprintEnabled"
                                                            value={webEditorSettings.centralDistributionCentralprintEnabled}
                                                            label={"Central print"}
                                                            disabled={true}
                                                            onChange={e => handleChange(e.target)}
                                                            error={errors.centralDistributionCentralprintEnabled}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <SidebarCheckbox
                                                    name="archiveOnlyEnabled"
                                                    value={webEditorSettings.archiveOnlyEnabled}
                                                    label={"Viewpoint/Archive"}
                                                    onChange={e => handleChange(e.target)}
                                                    error={errors.archiveOnlyEnabled}
                                                />
                                                {webEditorSettings.archiveOnlyEnabled &&
                                                    <div className="space-y-4 px-10 pt-1 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                        {viewpointArchiveOptions.map((viewpointArchiveOption) => (
                                                            <div key={viewpointArchiveOption.value} className="flex items-center">
                                                                <input
                                                                    id={viewpointArchiveOption.value}
                                                                    name="viewpointArchive-method"
                                                                    type="radio"
                                                                    className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300"
                                                                    checked={viewpointArchiveOption.value === webEditorSettings.archiveMethod}
                                                                    onChange={e => handleArchiveChange(viewpointArchiveOption.value)}
                                                                />
                                                                <label htmlFor={viewpointArchiveOption.value} className="ml-3 block text-sm font-medium text-gray-700">
                                                                    {viewpointArchiveOption.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                            <SidebarCheckbox
                                                name="localPrintEnabled"
                                                value={webEditorSettings.localPrintEnabled}
                                                label={"Local print"}
                                                onChange={e => handleChange(e.target)}
                                                error={errors.localPrintEnabled}
                                            />
                                            <SidebarCheckbox
                                                name="emailEnabled"
                                                value={webEditorSettings.emailEnabled}
                                                label={"Email"}
                                                onChange={e => handleChange(e.target)}
                                                error={errors.emailEnabled}
                                            />

                                        </div>
                                    </>
                                )}
                            </div>

                            <hr className="mx-4 sm:mx-6 my-1"></hr>

                            <div className="py-5">
                                <div className="pb-5 px-4 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">MFDX</h3>
                                </div>
                                <div class="w-full">
                                    <SidebarPassword
                                        name="mfdxClientSecret"
                                        value={webEditorSettings.mfdxClientSecret}
                                        label={"Client Secret"}
                                        onChange={e => handleChange(e.target)}
                                        error={errors.mfdxClientSecret}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Action buttons */}
            <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
                <div className="space-x-3 flex justify-end">
                    <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
                        onClick={handleSubmit}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </>
    );
}
