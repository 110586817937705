import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getPlanTier, issueCustomerInvoice, startCustomerPlan } from '../../../api/plan';
import { CheckIcon as CheckIconSolid, } from '@heroicons/react/solid';
import { RadioGroup } from '@headlessui/react'
import PaymentProviderSelect from './paymentProviderSelect';
import { BaseUrl } from '../../../api/api';
import { tailwindToast } from '../../../components/Toast/tailwindToast';
import { DefaultLayoutContext } from '../../../contexts';
import GenericModal from '../../../components/Modals/genericModal';
import ConfirmTierDownGradeModal from './confirmTierDownGradeModalContent';
export default function Purchase() {
    const defaultLayoutContext = useContext(DefaultLayoutContext);
    const [currencyPrice, setCurrencyPrice] = useState();
    const { planId } = useParams();
    const [planTier, setPlanTier] = useState();
    const [confirmDowngradeModalOpen, setConfirmDowngradeModalOpen] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const history = useHistory();
    const loadPlanTier = async () => {
        try {
            const plan = await getPlanTier(planId);
            const price = plan.planPrices.find(x => x.currency === defaultLayoutContext.customerDetails.country.currency);
            setCurrencyPrice(price);
            setPlanTier(plan);
        } catch (error) {
            tailwindToast.error("Failed to load plan");
        }
    }

    useEffect(() => {
        loadPlanTier();
    }, [planId]);
    const startPlan = async () => {
        try {
            setConfirmDowngradeModalOpen(false);
            var response = await startCustomerPlan(planId);
            setIsPurchasing(false);
            window.location.href = response.url;
        } catch (error) {
            tailwindToast.error("Failed to initiate checkout");
        }
    }
    const handleStartPlan = async (event) => {
        
        setIsPurchasing(true);
        if(!defaultLayoutContext.customerDetails.currentPlan){
            startPlan();
            return;
        }
        
        if (defaultLayoutContext.customerDetails.currentPlan.tierLevel > planTier.tierLevel) {
            setConfirmDowngradeModalOpen(true);
        } else {
            startPlan();
        }
    }
    return (
        <>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-gradient-to-b from-white to-gray-50">
                    <div className="pt-12 md:pt-18 px-4 sm:px-6 lg:px-8 max-w-2xl mx-auto divide-solid divide-y">
                        <div className="max-w-3xl mx-auto pb-2 sm:pb-4">
                            <h2 className="text-3xl font-extrabold text-gray-900">Order Checkout</h2>
                        </div>
                        {planTier && (
                            <>
                                <div className="pt-2 sm:pt-4 md:pt-8 divide-solid divide-y">
                                    <div className="grid grid-cols-2 gap-2 pb-2 sm:pb-4">
                                        <div><h2 className="text-lg leading-6 font-medium text-gray-900">Product</h2></div>
                                        <div><h2 className="text-lg leading-6 font-medium text-gray-900">Price</h2></div>
                                        <div>{planTier.planName}</div>
                                        <div>
                                            <span className="text-gray-900">
                                                {currencyPrice.price} <span className="text-gray-500">{currencyPrice.currency}</span> </span>{' '}
                                            <span className="text-gray-500">/mo</span>
                                        </div>
                                    </div>
                                    <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">Product Features</h2>
                                        <ul className="inline-block mt-2 sm:mt-4 md:mt-6 space-y-4 sm:mx-auto">
                                            {planTier.whatsIncluded.map((feature) => (
                                                <li key={feature} className="flex space-x-3">
                                                    <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                                    <span className="text-sm text-gray-500">{feature}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    {/* <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                        <h2 className="text-lg leading-6 font-medium text-gray-900">How would you like to pay?</h2>
                                        <div className="mt-2 sm:mt-4 md:mt-6 space-y-4 sm:mx-auto">
                                            <PaymentProviderSelect onChange={handlePaymentProviderSelect}></PaymentProviderSelect>
                                        </div>
                                    </div> */}
                                    <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                        <div className="max-w-xs mx-auto">
                                            <button
                                                className="inline-flex justify-center items-center bg-brand-pink border w-full border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-brand-pink-hover"
                                                onClick={handleStartPlan}
                                                disabled={isPurchasing}
                                            >
                                                {
                                                    isPurchasing && (
                                                        <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                    )
                                                }
                                                Buy Plan {' '}{currencyPrice.price} {currencyPrice.currency} /mo
                                            </button>
                                        </div>
                                    </div>
                                </div></>
                        )}

                    </div>
                </div>

            </div>
            <GenericModal open={confirmDowngradeModalOpen} setOpen={setConfirmDowngradeModalOpen} title={'Confirm downgrade'}
                onCancel={() => {
                    setConfirmDowngradeModalOpen(false);
                    setIsPurchasing(false);
                }}
                showCancelButton={true}
                onConfirm={() => {
                    startPlan();
                }}
            >
                <ConfirmTierDownGradeModal />
            </GenericModal>
        </>

    )
}
