import React, { useState, Fragment } from "react";

import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useClassNames } from "../../hooks/useClassNames";

export default function GenericSidebar({ open, setOpen, title, subTitle, children, width}) {
    const classNames = useClassNames();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 overflow-hidden z-50"
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className="absolute inset-0 overflow-hidden bg-gray-500 bg-opacity-50">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={
                                classNames.classNames(
                                    width ? width : 'max-w-lg',
                                    'w-screen '
                                )}>
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="flex-shrink-0">
                                        {/* Header */}
                                        <div className="px-4 py-6 bg-brand-pink sm:px-6">
                                            <div className="flex items-start justify-between space-x-3">
                                                <div className="space-y-1">
                                                    <Dialog.Title className="text-lg font-medium text-white">
                                                        {title}
                                                    </Dialog.Title>
                                                    {subTitle}
                                                </div>
                                                <div className="h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-red-400 rounded-md text-white hover:text-gray-500 focus:outline-none cursor-pointer"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    );
}
