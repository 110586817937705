import { ChatAlt2Icon, ChatAltIcon, ShareIcon, DocumentTextIcon, PencilAltIcon, DocumentIcon, DocumentSearchIcon, DocumentReportIcon, AdjustmentsIcon, SupportIcon, UsersIcon, ServerIcon } from "@heroicons/react/outline";
import { featureFlags } from "../../utils/features";
import { config } from '../../utils/config';
export const configuredProducts = [
    {
        productCode: "DA",
        title: "Studio",
        href: config.productUrl.smartforms,
        icon: ChatAlt2Icon,
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
        description:
            "Quickly compose smart documents that improves your customer dialog.",
        hasLicense: false,
        featureEnabled: true
    },
    {
        productCode: "SD",
        title: "Dialogs",
        href: config.productUrl.smartdocs,
        icon: ChatAltIcon,
        iconForeground: "text-yellow-700",
        iconBackground: "bg-yellow-50",
        description: "Get access to and use smart documents for communication that will put a smile on peoples faces.",
        hasLicense: false,
        featureEnabled: true
    },
    {
        productCode: "WF",
        title: "Workflow",
        href: config.productUrl.workflow,
        icon: ShareIcon,
        iconForeground: "text-yellow-700 transform rotate-90",
        iconBackground: "bg-yellow-50",
        description:
            "Combine Customer Communication features and external integrations into a Customer dialogue",
        hasLicense: false,
        featureEnabled: featureFlags.workflow
    },
    {
        productCode: "TL",
        title: "Text Library",
        href: config.productUrl.textlibrary,
        icon: DocumentTextIcon,
        iconForeground: "text-light-blue-700",
        iconBackground: "bg-light-blue-50",
        description: "Increase speed and compliance in your personal communication with predefined texts.",
        hasLicense: false,
        featureEnabled: true
    },
    {
        productCode: "DS",
        title: "Signature",
        href: config.productUrl.signing,
        icon: PencilAltIcon,
        iconForeground: "text-purple-700",
        iconBackground: "bg-purple-50",
        description: "Implement and manage secure digital signing of documents and forms and support advanced signing workflows.",
        hasLicense: false,
        featureEnabled: true
    },
    {
        productCode: "DG",
        title: "Archive",
        href: config.productUrl.archive,
        icon: DocumentIcon,
        iconForeground: "text-rose-700",
        iconBackground: "bg-rose-50",
        description: "A web client documents digital archive.",
        hasLicense: false,
        featureEnabled: featureFlags.digitalArchive
    },
    {
        productCode: "VP",
        title: "Viewpoint",
        href: config.productUrl.viewpoint,
        icon: DocumentSearchIcon,
        iconForeground: "text-light-blue-700",
        iconBackground: "bg-light-blue-50",
        description: "A web client lookup documents.",
        hasLicense: false,
        featureEnabled: featureFlags.viewPoint
    },
    {
        productCode: "LL",
        title: "Audit",
        href: config.productUrl.audit,
        icon: DocumentReportIcon,
        iconForeground: "text-green-700",
        iconBackground: "bg-green-50",
        description: "View and analyze activities that has been performed by users in CenterPoint.",
        hasLicense: false,
        featureEnabled: featureFlags.audit
    },
    {
        productCode: "AD",
        title: "Administration",
        route: "/admin/dashboard",
        icon: AdjustmentsIcon,
        iconForeground: "text-rose-700",
        iconBackground: "bg-rose-50",
        description: "Invite users, set up their roles and access and handle configurations.",
        hasLicense: false,
        internalLink: true,
        requireAdminUser: true,
        featureEnabled: true
    },
    {
        productCode: "SP",
        title: "Support",
        href: config.productUrl.support,
        icon: SupportIcon,
        iconForeground: "text-indigo-700",
        iconBackground: "bg-indigo-100",
        description: "Register errors or support questions",
        hasLicense: false,
        featureEnabled: featureFlags.support
    },
    {
        productCode: "PA",
        title: "Pingdox Admin",
        href: config.productUrl.pingdoxAdmin,
        icon: UsersIcon,
        iconForeground: "text-white",
        iconBackground: "bg-yellow-600",
        description: "License setup, customer access and pricing",
        hasLicense: false,
        featureEnabled: featureFlags.pingDoxAdmin
    },
    {
        productCode: "IA",
        title: "Interact Admin",
        href: config.productUrl.interactAdmin,
        icon: ServerIcon,
        iconForeground: "text-white",
        iconBackground: "bg-yellow-600",
        description: "Environment setup",
        hasLicense: false,
        featureEnabled: featureFlags.interactAdmin
    },
]