import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCustomerInfo } from '../../api/customer';
import { config } from '../../utils/config'
import './styles.scss';
import { AuthenticatedRoutesContext } from '../../contexts';
import { featureFlags } from '../../utils/features';
import {
  adminBillingFeatureCode,
  adminConfigurationFeatureCode,
  adminInvitesFeatureCode,
  adminUserFeatureCode as adminUserManagementFeatureCode
} from '../../utils/constants';
import { useHasFeature } from '../../hooks/useHasFeature';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useLocation } from 'react-use';
import { MenuBar } from '@metaforcelabs/metaforce-core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function AdminLayout({ nav, subMenuNav, children, hideNavigation }) {
  const authContext = useContext(AuthenticatedRoutesContext);
  const [customerInfo, setCustomerInfo] = useState();
  const location = useLocation();
  const history = useHistory();
  const { hasFeature } = useHasFeature();
  const isAdmin = useIsAdmin();

  const loadAdminLayoutData = async () => {
    const customer = await getCustomerInfo();
    setCustomerInfo(customer);
  };

  const NavigationMenuReactLinkElement = (
    name,
    route,
    currentCheckValue = nav === route.replace('/admin/', ''),
    featureEnabledCheckResult = true,
    children
  ) => {
    return {
      linkElement: <Link to={route}>{name}</Link>,
      name: name,
      route: route,
      current: currentCheckValue,
      featureEnabled: featureEnabledCheckResult,
      children
    };
  };

  const navigationMenu = [
    NavigationMenuReactLinkElement('Dashboard', '/admin/dashboard', nav === 'dashboard', isAdmin),
    NavigationMenuReactLinkElement(
      'Team',
      '/admin/team/manage',
      nav.includes('team/'),
      featureFlags.manageTeam &&
        (hasFeature(adminUserManagementFeatureCode) || hasFeature(adminInvitesFeatureCode)),
      [
        NavigationMenuReactLinkElement('Manage', '/admin/team/manage'),
        NavigationMenuReactLinkElement('Invite', '/admin/team/invite'),
        NavigationMenuReactLinkElement('Roles', '/admin/team/roles'),
        NavigationMenuReactLinkElement('Access', '/admin/team/groups')
      ]
    ),
    NavigationMenuReactLinkElement(
      'Attribute Store',
      '/admin/attributestore/production',
      nav.includes('attributestore/'),
      featureFlags.attributeStore &&
        authContext.centerPointUser.isSppUser === true &&
        hasFeature(adminConfigurationFeatureCode),
      [
        NavigationMenuReactLinkElement('Production', '/admin/attributestore/production'),
        NavigationMenuReactLinkElement('Test', '/admin/attributestore/test'),
        NavigationMenuReactLinkElement('Development', '/admin/attributestore/development')
      ]
    ),
    NavigationMenuReactLinkElement(
      'Settings',
      '/admin/settings/notifications',
      nav.includes('settings/'),
      featureFlags.settings && hasFeature(adminConfigurationFeatureCode),
      [
        NavigationMenuReactLinkElement('Notifications', '/admin/settings/notifications'),
        NavigationMenuReactLinkElement('Account', '/admin/settings/account'),
        NavigationMenuReactLinkElement('Branding', '/admin/settings/branding'),
        NavigationMenuReactLinkElement('Retention policies', '/admin/settings/retentionPolicies')
      ]
    ),
    NavigationMenuReactLinkElement('ViewPoint', '/admin/viewpoint'),
    NavigationMenuReactLinkElement(
      'Interact',
      '/admin/interact/general',
      nav.includes('interact/'),
      featureFlags.viewPoint && hasFeature(adminConfigurationFeatureCode),
      [
        NavigationMenuReactLinkElement('General', '/admin/interact/general'),
        NavigationMenuReactLinkElement('Interact SOAP Clients', '/admin/interact/soap'),
        NavigationMenuReactLinkElement('WebEditor', '/admin/interact/webeditorsettings')
      ]
    ),
    NavigationMenuReactLinkElement(
      'Billing',
      '/admin/billing/overview',
      nav.includes('billing/'),
      featureFlags.billing && hasFeature(adminBillingFeatureCode),
      [
        NavigationMenuReactLinkElement('Overview', '/admin/billing/overview'),
        NavigationMenuReactLinkElement('Invoices', '/admin/billing/invoices'),
        NavigationMenuReactLinkElement('Payment Methods', '/admin/billing/paymentmethods')
      ]
    ),
    NavigationMenuReactLinkElement(
      'Integration',
      '/admin/integration/digitalsigning',
      nav.includes('integration/'),
      featureFlags.integration && hasFeature(adminConfigurationFeatureCode),
      [
        NavigationMenuReactLinkElement('Digital Signing', '/admin/integration/digitalsigning'),
        NavigationMenuReactLinkElement('Digital Archive', '/admin/integration/digitalarchive'),
        NavigationMenuReactLinkElement('API Clients', '/admin/integration/apiclients'),
        NavigationMenuReactLinkElement('Auditlog Forwarding', '/admin/integration/auditlog-forward')
      ]
    ),
    NavigationMenuReactLinkElement(
      'Version',
      '/admin/version/releasePackages',
      nav.includes('version/') ||
        (nav === 'releasepackagedetails' &&
          location?.state?.state?.previouspage === '/admin/version/releasepackages'),
      true,
      [
        NavigationMenuReactLinkElement(
          'Release Packages',
          '/admin/version/releasePackages',
          nav === 'version/releasePackages' ||
            (nav === 'releasePackageDetails' &&
              location?.state?.state?.previousPage === '/admin/version/releasePackages')
        ),
        NavigationMenuReactLinkElement(
          'Customer Notifications',
          '/admin/version/customerNotifications',
          nav === 'version/customerNotifications' ||
            (nav === 'releasePackageDetails' &&
              location?.state?.state?.previousPage === '/admin/version/customerNotifications')
        )
      ]
    )
  ];

  useEffect(() => {
    loadAdminLayoutData();
  }, []);

  return (
    <>
    <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
      <MenuBar
        subMenuNav={'releasePackages'}
        navigation={navigationMenu}
        homeButtonLink={config.centerpointUiBaseUrl}
        customerName={customerInfo?.name}
        customerOrgNumber={customerInfo?.orgNumber}
        internalHomeButtonRedirect={() => history.push('/')}
        userProfileImage={authContext.centerPointUser.userProfileImage}
        helpLink={'https://mfdoc.blob.core.windows.net/mfdocs/Global.html'}
      />
      {children}
    </div>
    </>
  );
}
