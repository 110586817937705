import {BaseUrl, get, post} from './api';

const controllerName = "api/AdminIntegration"

export const updateCustomerDigitalSigningProvider = async (requestModel) => {
  return post(controllerName + '/digitalsigning', requestModel, null, BaseUrl.Centerpoint)
}

export const testCustomerDigitalSigningProvider = async (requestModel) => {
  return post(controllerName + '/digitalsigning/test', requestModel, null, BaseUrl.Centerpoint)
}

export const generateAccessKeys = async (requestContent) => {
  return post(controllerName + '/digitalsigning/generateAccessKeys', requestContent, null, BaseUrl.Centerpoint)
}

export const updateDigitalSigningArchiveIntegrationApi = async (requestModel) => {
  return post(controllerName + '/digitalsigningarchiveintegration', requestModel, null, BaseUrl.Centerpoint)
}

export const getDigitalSigningProviders = async () => {
  return get(controllerName + '/digitalsigning/providers', null, BaseUrl.Centerpoint);
}

export const getAllUserFoldersFlatApi = async () => {
  return get('api/DigitalArchiveFolders/getAllUserFoldersFlat', null, BaseUrl.Centerpoint);
}

export const getDigitalSigningConfigurationApi = async () => {
  return get(controllerName + '/digitalsigningconfiguration', null, BaseUrl.Centerpoint);
}
