import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const paymentProviders = [
    { name: 'Credit Card', id: 'stripe' },
    { name: 'Request Invoice', id: 'invoice' },
];
// const plans = [
//     { name: 'Startup', priceMonthly: 29, priceYearly: 290, limit: 'Up to 5 active job postings' },
//     { name: 'Business', priceMonthly: 99, priceYearly: 990, limit: 'Up to 25 active job postings' },
//     { name: 'Enterprise', priceMonthly: 249, priceYearly: 2490, limit: 'Unlimited active job postings' },
// ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PaymentProviderSelect({ onChange }) {
    const [selectedProvider, setSelectedProvider] = useState(paymentProviders[0])

    useEffect(() => {
        onChange(selectedProvider);
    }, [selectedProvider])

    const handlePaymentProviderChange = (value) => {
        setSelectedProvider(value);
    }

    return (
        <RadioGroup value={selectedProvider} onChange={handlePaymentProviderChange}>
            <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
            <div className="relative bg-white rounded-md -space-y-px">
                {paymentProviders.map((provider, planIdx) => (
                    <RadioGroup.Option
                        key={provider.name}
                        value={provider}
                        className={({ checked }) =>
                            classNames(
                                planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                planIdx === provider.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                            )
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <div className="flex items-center text-sm">
                                    <span
                                        className={classNames(
                                            checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                            active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                            'h-4 w-4 rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                    >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <RadioGroup.Label
                                        as="span"
                                        className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'ml-3 font-medium')}
                                    >
                                        {provider.name}
                                    </RadioGroup.Label>
                                </div>
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
