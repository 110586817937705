import { useContext, useEffect, useState } from "react";
import { AuthenticatedRoutesContext } from "../contexts";
import { adminProductId } from "../utils/constants";
export const useIsAdmin = () => {
    const authContext = useContext(AuthenticatedRoutesContext);
    const [isAdmin, setIsAdmin] = useState(null);

    const myUser = authContext.centerPointUser;
    useEffect(() => {
        setIsAdmin(myUser && myUser.customerUserAccess?.productFeatureAccessSelections.find(x => x.productId === adminProductId)?.usageFeature.hasAccess);
    }, [myUser])
    return isAdmin;
}