import React from 'react';

export const DocumentSidebarContext = React.createContext();

export const ManageTeamContext = React.createContext();
export const DefaultLayoutContext = React.createContext();
export const AuthenticatedRoutesContext = React.createContext();
export const RoleTableContext = React.createContext();
export const GroupTableContext = React.createContext();
export const DigitalSigningIntegrationContext = React.createContext();
