import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastAction } from '../../../hooks/useToastAction';
import { getDashboard } from '../../../api/dashboard';
import { adminProductId, dashboardAreas } from '../../../utils/constants';
import { AuthenticatedRoutesContext } from '../../../contexts';
import ReleasePackagesTable from './releasePackagesTable';
import { GetReleasePackages } from '../../../api/version';
import { ContentWrapper } from '@metaforcelabs/metaforce-core';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dashboard() {
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [releasePackages, setReleasePackages] = useState([]);
  const loadAction = useToastAction();
  const loadReleasePackagesData = useToastAction();
  const authContext = useContext(AuthenticatedRoutesContext);

  const loadDefaultData = async () => {
    setIsLoaded(false);
    loadAction.execute(async () => {
      const customerDashboardData = await getDashboard();
      setDashboardData(customerDashboardData || []);
      setIsLoaded(true);
    }, 'Failed to load');
  };

  const fetchReleasePackagesData = async () => {
    loadReleasePackagesData.execute(async () => {
      const request = {
        fromDate: null,
        toDate: null,
        searchString: '',
        packageState: 1,
        quantity: 5
      };
      const releasePackages = await GetReleasePackages(request);
      setReleasePackages(releasePackages || []);
    }, 'Failed to load release packages data');
  };

  useEffect(() => {
    fetchReleasePackagesData();
    if (isLoaded) {
      return;
    }
    loadDefaultData();
  }, []);

  function getAreaColor(area) {
    switch (area) {
      case dashboardAreas.team:
        return 'bg-team';
      case dashboardAreas.archive:
        return 'bg-archive';
      case dashboardAreas.billing:
        return 'bg-billing';
      case dashboardAreas.audit:
        return 'bg-audit';
      default:
        return 'bg-red-400';
    }
  }

  const AdminDashboardTile = ({ tileProps }) => {
    const hasFeature = () => {
      if (!tileProps.requiredFeatureCode) return true;

      const product =
        authContext.centerPointUser.customerUserAccess.productFeatureAccessSelections.find(
          (x) => x.productId === adminProductId
        );
      const ret = product.featureSelections.find(
        (x) => x.featureCode === tileProps.requiredFeatureCode
      )?.hasAccess;
      return !!ret;
    };

    return (
      <div
        key={tileProps.title}
        className={classNames(
          'col-span-6 md:col-span-4 xl:col-span-3',
          getAreaColor(tileProps.area),
          'text-white flex flex-col rounded-md border border-gray-200 relative bg-white p-6 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:shadow-sm'
        )}
      >
        <div className="flex-shrink-0 flex justify-center items-stretch w-full font-medium rounded-l-md">
          <a
            className={classNames('focus:outline-none', hasFeature() ? ' cursor-pointer' : '')}
            href={tileProps.url}
            onClick={() => {
              if (hasFeature()) history.push(tileProps.route);
            }}
            target="_blank"
            rel="noreferrer"
          >
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {tileProps.title}
          </a>
        </div>
        <div className="mt-2">
          <p className="text-xs flex items-center justify-center ">{tileProps.descriptionSmall}</p>
        </div>
        <div className="mt-2 min-h-0 h-full flex items-center justify-center">
          <p
            className={classNames(
              'font-bold',
              tileProps.descriptionLarge.length <= 5 ? 'text-8xl' : 'text-3xl'
            )}
          >
            {tileProps.descriptionLarge}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <ContentWrapper>
        {!loadAction.isExecuting && isLoaded && (
          <div className="mt-5 pt-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="mt-5 grid grid-cols-12 gap-5">
              {dashboardData.map((action) => (
                <AdminDashboardTile tileProps={action} key={'adminDashboardTile_' + action.title} />
              ))}
            </div>
            {releasePackages.length > 0 && (
              <div className="mt-5 pt-10 max-w-7xl">
                <div className="underline font-semibold my-5">Latest software updates</div>
                <ReleasePackagesTable releasePackages={releasePackages} />
              </div>
            )}
          </div>
        )}
      </ContentWrapper>
    </>
  );
}
