import React, { useContext } from 'react'
import { CheckIcon as CheckIconSolid, } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { DefaultLayoutContext } from '../../contexts';
import { formatThousand } from '../../utils/numbers';


export default function PlanTier({ tier }) {
    const defaultLayoutContext = useContext(DefaultLayoutContext);
    console.log(defaultLayoutContext)
    const currencyPrice = tier.planPrices.find(x => x.currency === defaultLayoutContext.customerDetails.country.currency);
    // const sekPrice = tier.planPrices.find(x=> x.currency === currency);
    const PlanPrice = ({ currencyPrice }) => {
        return (<>
            <span className="text-3xl font-extrabold text-gray-900">
                {formatThousand(currencyPrice.price, 0)} <span className="text-base font-medium text-gray-500">{currencyPrice.currency.toUpperCase()}</span></span>{' '}
            <span className="text-base font-medium text-gray-500">/mo</span>
        </>)
    }

    const NoPlanPrice = () => {
        return (<>
            <span className="text-3xl font-extrabold text-gray-900">
                Per agreement
            </span>
        </>)
    }    

    return (
        <div className="w-full sm:w-1/2 lg:w-1/4 px-1">
            <div className="h-full border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                <div className="p-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.planName}</h2>
                    <p className="mt-4 text-sm text-gray-500">{tier.planDescription}</p>
                    <p className="mt-8">
                        {
                            tier.prePriced ? (
                                <PlanPrice currencyPrice={currencyPrice}></PlanPrice>
                            ):
                            (
                                <NoPlanPrice></NoPlanPrice>
                            )
                        }
                        
                    </p>
                    {
                        defaultLayoutContext.customerDetails.currentPlan?.tierLevel === tier.tierLevel && (
                            <span
                                className="mt-8 block w-full text-gray-600 bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-center"
                            >Current</span>
                        )
                    }
                    {
                        defaultLayoutContext.customerDetails.currentPlan?.tierLevel !== tier.tierLevel && !tier.prePriced && (
                            <span
                                className="mt-8 block w-full text-gray-600 bg-gray-300 border border-transparent rounded-md py-2 text-sm font-semibold text-center"
                            >Call us</span>
                        )
                    }                    
                    {
                        defaultLayoutContext.customerDetails.currentPlan?.tierLevel !== tier.tierLevel && tier.prePriced && (
                            <Link
                                className="mt-8 block w-full bg-brand-pink border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-brand-pink-hover"

                                to={`/plans/purchase/${tier.id}`}>
                                {/* Buy {tier.planName} */}
                                Select {tier.planName} Plan
                            </Link>
                        )
                    }
                </div>
                <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                    <ul className="mt-6 space-y-4">
                        {tier.whatsIncluded.map((feature) => (
                            <li key={feature} className="flex space-x-3">
                                <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                <span className="text-sm text-gray-500">{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>)
}
