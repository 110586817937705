import { get, post, BaseUrl, put } from './api'

export const getCustomerDetails = async (token) => {
  return await get(`api/Customer/GetCustomerDetails`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }, BaseUrl.Centerpoint);
}
export const getCustomerInfo = async () => {
  return await get(`api/Customer/GetCustomer`, null,
    BaseUrl.Centerpoint);
}
export const getCustomerNotificationEmails = async () => {
  return await get(`api/Customer/CustomerNotificationEmails`, null,
    BaseUrl.Centerpoint);
}
export const postCustomerNotificationEmails = async (data) => {
  return await post(`api/Customer/CustomerNotificationEmails`, data,
    BaseUrl.Centerpoint);
}
export const GetSendReleasePackageNotificationsStatus = async () => {
  return await get(`api/Customer/SendReleasePackageNotificationsStatus`, null,
    BaseUrl.Centerpoint);
}
export const PostSendReleasePackageNotificationsStatus = async (data) => {
  return await post(`api/Customer/SendReleasePackageNotificationsStatus`, data,
    BaseUrl.Centerpoint);
}
export const createCustomer = async (token, request) => {
  return await post(`api/Customer`, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }, BaseUrl.Centerpoint);
}
export const updateCustomer = async (token, request) => {
  return await put(`api/Customer`, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }, BaseUrl.Centerpoint);
}
export const customerExists = async (token, request) => {
  return await post(`api/Customer/ValidateOrgNumber`, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }, BaseUrl.Centerpoint);
}

export const updateCustomerWhiteLabel = async (request) => {
  return await post(`api/Customer/WhiteLabel`, request, null, BaseUrl.Centerpoint);
}

export const getCustomerWhiteLabel = async () => {
  return await get(`api/Customer/WhiteLabel`, null,
    BaseUrl.Centerpoint);
}
export const getWhiteLabelDefault = async () => {
  return await get(`api/Customer/WhiteLabel/default`, null,
    BaseUrl.Centerpoint);
}

export const getCustomerAuditLogForwardConfiguration = async () => {
  return await get(`api/Customer/AuditlogForward`, null,
    BaseUrl.Centerpoint);
}

export const updateCustomerAuditLogForwardConfiguration = async (request) => {
  return await post(`api/Customer/AuditlogForward`, request, null, BaseUrl.Centerpoint);
}