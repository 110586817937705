const NotActiveCustomer = () => {
  return (
    <div className="absolute flex justify-center items-center bg-white bg-opacity-50 max-w-screen-2xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col p-10 max-w-4xl rounded-xl shadow-2xl bg-white border border-gray-200">
        <h1 className="text-3xl font-bold">Your account is not active yet</h1>
        <h3 className="mt-4 text-gray-600">
          Please be aware that you company account is not active right now. Because of that you are
          not able to access any applications in our eco system before proper verification. 
          <br/><br/>Your account will be approved within the next 24 hours - you will receive notification when it will be activated.
        </h3>
      </div>
    </div>
  );
};

export default NotActiveCustomer;
